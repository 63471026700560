
import { defineComponent, onMounted, ref, watch, reactive, toRefs } from 'vue'
import MyHeader from '@/components/MyHeader.vue'
import MyFooter from '@/components/MyFooter.vue'
import MyPage from '@/components/MyPage.vue'
import ScrollReveal from 'scrollreveal'
// 首先的从vue-router中导入useRouter
import { useRouter } from 'vue-router'
import { HARDWARES, SOFTWARES } from '@/utils/database'

export default defineComponent({
  name: 'Home',
  components: {
    MyHeader,
    MyFooter,
    MyPage
  },
  setup(props, ctx) {
    // 实例化路由
    const router = useRouter()
    /* --------------------定义banner模块的数据变量-------------------------------- */

    const businessList: string[] = ['国产自主研发', '一体化系统', '软件定义建筑', '数字孪生', '智慧物联']
    const moduleList: object[] = [
      { img: require('@/assets/index/banner1.png') }
    ]
    const currentBannerIndex = ref(0)

    /* --------------------定义banner模块的数据变量结束-------------------------------- */
    /* --------------------定义案例开始-------------------- */
    const caseList: object[] = [
      { img: require('@/assets/case/td.png'), title: '广州天德广场智能化' },
      { img: require('@/assets/case/hc.png'), title: '海南华彩海口湾智慧广场' },
      { img: require('@/assets/case/gzyk.png'), title: '广州粤科金融大厦智能化' },
      { img: require('@/assets/case/hsf.jpg'), title: '华南师范大学附属中学知识城校区' },
      { img: require('@/assets/case/lt.png'), title: '海南联通智慧大厦' },
      { img: require('@/assets/case/tcds.png'), title: '广州天财大厦智能化' },
      { img: require('@/assets/case/gl.png'), title: '广铝总部智慧大厦' },
      { img: require('@/assets/case/kzzx.jpg'), title: '广州大学抗震中心' },
      { img: require('@/assets/case/nfcc.png'), title: '浙江宁波市NFCC智慧广场' },
      { img: require('@/assets/case/yncx.png'), title: '云南楚雄生产调度楼智能化' },
      { img: require('@/assets/case/zt.png'), title: '北京中铁建工总部智能化' },
      { img: require('@/assets/case/xzlf.png'), title: '西藏领峰物流园智能化' },
      { img: require('@/assets/case/yljkg.png'), title: '伊利健康谷智慧园区' },
      { img: require('@/assets/case/sdhc.png'), title: '山东豪驰工业智慧园区' },
      { img: require('@/assets/case/hzch.png'), title: '浙江传化智慧园区' },
      { img: require('@/assets/case/ynyd.png'), title: '云南移动呈贡数据中心园区' },
      { img: require('@/assets/case/ynbs.png'), title: '云南保山综合管廊智能化' },
      { img: require('@/assets/case/yngl.png'), title: '云南芒市综合管廊智能化' },
      { img: require('@/assets/case/ghlgl.png'), title: '广州广花路管廊智能化' },
      { img: require('@/assets/case/zjnb.png'), title: '浙江宁波通途路综合管廊智能化' },
      { img: require('@/assets/case/szgl.png'), title: '深圳华夏二路智慧管廊' },
      { img: require('@/assets/case/whhjh.png'), title: '武汉黄家湖综合管廊智能化' }
    ]

    /* --------------------定义案例结束-------------------- */

    /* --------------------定义解决方案模块的数据变量开始-------------------------------- */
    const solutionModule: string[] = ['智慧建筑', '智慧管廊', '智慧仓储']
    const currnetSolutionModuleIndex = ref(0)
    const solutionList: any = ref([])
    const pageList: any = ref([])
    const buildingSolutionList: object[] = [
      { img: require('@/assets/index/solution_building1.png'), key: 'building', title: '智慧建筑解决方案', content: '以Turbo-BOSS智能建筑管理平台为核心，简化了前端硬件设备的种类，功能集中以软件形态的虚拟化方式体现。' },
      { img: require('@/assets/index/solution_building8.png'), key: 'garden', title: '智慧园区综合解决方案', content: '实现了多信息协同联动，有效的降低了企业运营成本，提高工作效率，加强各类园区创新、服务和管理能力。' },
      { img: require('@/assets/index/solution_building6.png'), key: 'school', title: '智慧学校综合解决方案', content: '促进学校安全管理和应急处置的精细化、专业化和及时性。促进校园生活和教学智能化、便捷化' },
      { img: require('@/assets/index/solution_building3.png'), key: 'engine-room', title: '智慧机房监控系统解决方案', content: '通过5G拥抱物联网，基于5G+IOT+ESIM新兴技术，实现落地动环监控物联网5G的解决方案。' },
      { img: require('@/assets/index/solution_building4.png'), key: 'parking', title: '智慧停车场综合解决方案', content: '为了解决“停车难”问题，捍御者专门研发一套智慧、高效的无卡、无人值守停车场管理解决方案。' },
      { img: require('@/assets/index/solution_building5.png'), key: 'library', title: '智慧图书馆综合解决方案', content: '利用多种网络互连，、从而实现图书馆信息资源服务提供与读者用户知识需求之间的“智能化互动”' },
      { img: require('@/assets/index/solution_building7.png'), key: 'hospital', title: '智慧医院综合解决方案', content: '实现医院分级诊疗，资源共享，实现医院内部各个子系统的互联互通，高效运转，提升用户体验，降低差错。' },
      { img: require('@/assets/index/solution_building2.png'), key: 'park', title: '智慧公园综合解决方案', content: '智慧公园建设从顶层设计以“服务游客”为中心，围绕公园的建设要求和总体规划，结合国内一流、国际先进的设计理念。' }
    ]
    const pipeSolutionList: object[] = [
      { img: require('@/assets/index/solution_pipe1.png'), key: 'safety', title: '封闭空间融合安防通信系统解决方案', content: '针对封闭空间的安防、监控、通信管理的特征,建立统一的服务中间件，标准化接口，融合集成。' }
    ]
    const storeSolutionList: object[] = [
      { img: require('@/assets/index/solution_store1.png'), key: 'store', title: '智能仓储管理解决方案', content: '务流程，涵盖货物入库、质检、上架、维修保养、移库、出库等业务流程。' }
    ]
    // 智慧交通在solutionModule中去掉了，目前用不到
    const trafficSolutionList: object[] = [
      { img: require('@/assets/index/solution_traffic1.png'), key: 'metro', title: '地铁综合监控系统解决方案', content: '结合公司在远程控制等方面的优势，融合了捍御者核心技术和产品功能，提供给客户完整的综合监控解决方案。' },
      { img: require('@/assets/index/solution_traffic2.png'), key: 'monitor', title: '高速公路监控方案', content: '捍御者高速公路监控系统，满足国家智慧高速规划，提供高速公路全程监控的自动化管理工具。' }
    ]
    const currentSolutionPage = ref(1)
    solutionList.value = buildingSolutionList
    /**
     * @description 计算当前有多少页
     * @author 林育宏
     * @date 2020-10-29
     */
    function countPageList() {
      if (solutionList.value.length > 3) {
        for (let i = 0; i < solutionList.value.length - 2; i++) {
          pageList.value.push(i)
        }
      } else {
        pageList.value = []
      }
    }
    countPageList()
    // 当前模块变化时， 页码回到1开始
    watch(currnetSolutionModuleIndex, () => {
      solutionList.value = currnetSolutionModuleIndex.value === 0 ? buildingSolutionList : currnetSolutionModuleIndex.value === 1 ? pipeSolutionList : currnetSolutionModuleIndex.value === 2 ? storeSolutionList : trafficSolutionList
      currentSolutionPage.value = 1 // 修改当前页码
      // 算出有多少个小圆点
      countPageList()
    })
    // 跳转到解决方案详情页
    function jumpSolutionDetail(key: string) {
      router.push({
        path: 'solution-detail',
        query: { type: key }
      })
    }
    /* --------------------定义解决方案模块的数据变量结束-------------------------------- */
    /* --------------------定义关于我们模块的数据变量开始-------------------------------- */
    const aboutList: object[] = [
      { icon: 'iconjianzhu', title: '智慧建筑系统', content: '颠覆传统建筑智能化，孕育基础设施新生态' },
      { icon: 'iconguandao1', title: '智慧管廊系统', content: '专注于基础设施，提供相关系统性解决方案' },
      { icon: 'iconzizhuyanfa', title: '自主研发产品', content: '持续技术研究，为客户提供相关产品' }
    ]
    /* --------------------定义关于我们模块的数据变量结束-------------------------------- */
    /* --------------------定义产品设备模块的数据变量开始-------------------------------- */
    function getCurrentData(module: string) {
      const hardwareList = HARDWARES.filter(item => item.module === module).map(item => {
        const devieceData = item.devieceData || {}
        return {
          type: '硬件',
          module: item.module,
          key: item.name,
          zhName: devieceData.zhName,
          enName: devieceData.enName,
          intro: devieceData.content,
          img: devieceData.img,
          sortNum: item.sortNum
        }
      });
      hardwareList.sort((a, b) => a.sortNum - b.sortNum);

      const sortwareList = SOFTWARES.filter(item => item.module === module).map(item => {
        const devieceData = item.devieceData || {}
        return {
          type: '软件',
          module: item.module,
          key: item.name,
          zhName: devieceData.zhName,
          enName: devieceData.enName,
          intro: devieceData.content,
          img: devieceData.img,
          sortNum: item.sortNum
        }
      });
      sortwareList.sort((a, b) => a.sortNum - b.sortNum);
      return [...hardwareList, ...sortwareList];
    }

    const productModule: string[] = ['建筑系统', '管廊系统', '视频安防', '智慧教育'];
    const currnetProductModuleIndex = ref(0)
    const productList: any = ref([])
    const buildingProductList: object[] = getCurrentData('建筑');
    const pipeProductList: object[] = getCurrentData('管廊');
    // const storeProductList: object[] = getCurrentData('仓储');
    const videoProductList: object[] = getCurrentData('视频');
    // const communicationProductList: object[] = getCurrentData('通讯');
    const educationProductList: object[] = getCurrentData('教育');

    productList.value = buildingProductList
    const currentProductPage = ref(1)
    const currnetProductIndex = ref(0)
    const currnetProductHover = ref(-1)
    const currentProduct = ref(productList.value[currnetProductIndex.value])
    // 当前模块变化时， 修改数据
    watch(currnetProductModuleIndex, () => {
      productList.value = currnetProductModuleIndex.value === 0 ? buildingProductList : currnetProductModuleIndex.value === 1 ? pipeProductList : currnetProductModuleIndex.value === 2 ? videoProductList : educationProductList;
      currentProductPage.value = 1 // 修改当前页码
      currnetProductIndex.value = (currentProductPage.value - 1) * 4 // 修改当前产品项， 从0开始
      currentProduct.value = productList.value[currnetProductIndex.value] // 修改当前左侧预览项
    })
    // 当前项索引变化时， 当前项跟着变
    watch(currnetProductIndex, () => {
      currentProduct.value = productList.value[currnetProductIndex.value]
    })
    // 跳转到产品设备模块详情页
    function jumpProductDetail() {
      router.push({
        path: currentProduct.value.type === '硬件' ? 'hardware-detail' : 'software-detail',
        query: { type: currentProduct.value.key }
      })
    }
    /**
     * @description 修改产品模块当前页码
     * @author 林育宏
     * @date 2020-10-28
     */
    function changeCurrentPage(index: number) {
      currentProductPage.value = index // 修改当前页码
      currnetProductIndex.value = (currentProductPage.value - 1) * 4 // 修改当前产品项， 从0开始
      currentProduct.value = productList.value[currnetProductIndex.value] // 修改当前左侧预览项
    }
    /* --------------------定义产品设备模块的数据变量结束-------------------------------- */
    /* --------------------定义资质证书模块的数据变量开始-------------------------------- */
    const certificateList: object[] = [
      { img: require('@/assets/index/certificate1.png') },
      { img: require('@/assets/index/certificate2.png') },
      { img: require('@/assets/index/certificate3.png') },
      { img: require('@/assets/index/certificate4.png') },
      { img: require('@/assets/index/certificate5.png') },
      { img: require('@/assets/index/certificate6.png') },
      { img: require('@/assets/index/certificate7.png') },
      { img: require('@/assets/index/certificate8.png') },
      { img: require('@/assets/index/certificate9.png') },
      { img: require('@/assets/index/certificate10.png') },
      { img: require('@/assets/index/certificate11.png') },
      { img: require('@/assets/index/certificate12.png') },
      { img: require('@/assets/index/certificate1.png') },
      { img: require('@/assets/index/certificate2.png') },
      { img: require('@/assets/index/certificate3.png') },
      { img: require('@/assets/index/certificate4.png') },
      { img: require('@/assets/index/certificate5.png') },
      { img: require('@/assets/index/certificate6.png') }
    ]
    /* --------------------定义资质证书模块的数据变量结束-------------------------------- */

    return {
      jumpSolutionDetail, // 跳转到解决方案详情页
      jumpProductDetail, // 跳转到产品设备详情页
      currentBannerIndex, // 轮播图当前项
      businessList, // 业务数据列表
      moduleList, // 模块数据列表
      solutionModule, // 解决方案模块数据
      currnetSolutionModuleIndex, // 当前解决方案模块索引
      solutionList, // 解决方案列表
      currentSolutionPage, // 当前解决方案的分页, 大于3个方案显示下一页
      pageList, // 分页列表
      aboutList, // 关于我们数据列表
      productModule, // 产品设备模块数据
      currnetProductModuleIndex, // 当前产品模块索引
      currnetProductIndex, // 当前产品项索引
      currentProduct, // 当前产品
      currentProductPage, // 当前产品的分页, 大于4个产品显示下一页
      changeCurrentPage, // 修改当前产品的分页
      productList, // 产品列表
      currnetProductHover, // 当前产品项 -- 做动效用
      certificateList, // 资质证书模块数据列表
      caseList // 公司案例列表
    }
  },
  // 添加页面滚动加载
  mounted() {
    ScrollReveal().reveal('.case .zh-title', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.case .en-title', {
      distance: '500px',
      delay: 400,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.case .common-module', {
      distance: '500px',
      delay: 600,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.solution .zh-title', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.solution .en-title', {
      distance: '500px',
      delay: 400,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.solution .common-module', {
      distance: '500px',
      delay: 600,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.solution .solution-case', {
      distance: '500px',
      delay: 800,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.about .about-item', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.about .card .zh-title', {
      distance: '100px',
      delay: 400,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.about .card .en-title', {
      distance: '100px',
      delay: 600,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.about .card .content', {
      distance: '100px',
      delay: 800,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.product .zh-title', {
      distance: '500px',
      delay: 200,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.product .en-title', {
      distance: '500px',
      delay: 400,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.product .common-module', {
      distance: '500px',
      delay: 600,
      duration: 1000,
      origin: 'bottom'
    })
    ScrollReveal().reveal('.product .product-case', {
      distance: '500px',
      delay: 800,
      duration: 1000,
      origin: 'bottom'
    })
  }
})

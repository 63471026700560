import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3b97b471"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageList, (item) => {
      return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass(item === _ctx.currentPage ? 'page-item active-item' : 'page-item'),
        key: item,
        onClick: ($event: any) => (_ctx.$emit('change-page', item))
      }, _toDisplayString(item), 11, _hoisted_2))
    }), 128))
  ]))
}

import { defineComponent, ref, computed, watch } from 'vue'

export default defineComponent({
  name: 'MyPage',
  props: {
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 4
    },
    total: {
      type: Number,
      default: 0
    }
  },
  emits: ['change-page'],
  setup(props, ctx) {
    const pageList: any = ref([])
    const totalPage = computed(() => {
      return Math.ceil(props.total / props.pageSize)
    })
    function getPageList() {
      pageList.value = []
      for (let i = 1; i <= totalPage.value; i++) {
        pageList.value.push(i)
      }
    }

    getPageList()

    // 当前项索引变化时， 当前项跟着变
    watch(totalPage, () => {
      getPageList()
    })
    return {
      pageList
    }
  }
})
